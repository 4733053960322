.or-login-with-divider {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  gap: var(--spacer-smaller);
  align-items: center;
  text-align: center;
  color: var(--color-neutral-darkest);

  &::after,
  &::before {
    background-color: var(--color-neutral);
    display: block;
    flex: 1;
    height: 1px;
    content: '';
  }
}

.identity-provider-error {
  text-transform: capitalize;
}
