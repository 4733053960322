.default {
  background-color: var(--color-mint-light);
  background: var(--color-mint-light) url(./background.jpg) no-repeat right bottom;
  background-size: cover;
  color: var(--color-white);
  display: flex;
  align-items: flex-end;

  .slogan {
    max-width: 450px;
    width: 100%;
    padding: 60px;
  }

  .title {
    font-size: 36px;
    line-height: 1.2;
    opacity: 0;
    animation: fadeSlideIn 0.5s 0.7s forwards;
  }

  .subtitle {
    opacity: 0;
    animation: fadeSlideIn 0.3s 0.9s forwards;
    font-weight: 700;
    color: var(--color-mint-lightest);
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(80%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
