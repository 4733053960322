.background {
  background-color: var(--color-mint-lightest);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('./images/nl.jpg');

  &.language {
    &-nl {
      background-image: url('./images/nl.jpg');
    }
  }
}
