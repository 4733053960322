.background {
  background-color: var(--color-mint-lightest);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('./images/en.png');

  &.language {
    &-de {
      background-image: url('./images/de.png');
    }
    &-en {
      background-image: url('./images/en.png');
    }
    &-es {
      background-image: url('./images/es.png');
    }
    &-fr {
      background-image: url('./images/fr.png');
    }
    &-it {
      background-image: url('./images/it.png');
    }
    &-nl {
      background-image: url('./images/nl.png');
    }
  }
}
