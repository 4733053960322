@import 'variables.css';

.marketing-banners {
  flex: 1;
  display: flex;

  > * {
    flex: 1;
  }

  @media (--mobile-viewport-width) {
    display: none;
  }
}
