@import '@teamleader/ahoy/es/index.css';
@import 'variables.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: var(--font-family-inter);
  background: var(--color-white);
  min-width: 320px;
  color: var(--color-teal-darkest);
}

button,
p,
input,
textarea {
  font-family: var(--font-family-inter);
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
}

/* Ios zooms in on inputs with a font smaller than 16px */
input,
textarea {
  font-size: 16px;
}

/* Remove shadow on input elements (ios) */
textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  -webkit-appearance: none;
}

.app {
  min-height: 100vh;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }

  .page-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: var(--spacer-regular);

    .logo {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      position: absolute;
      right: var(--spacer-bigger);
      top: var(--spacer-bigger);

      @media (--mobile-viewport-width) {
        position: static;
      }
    }

    .inner {
      width: 100%;
      max-width: 420px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .inner-content {
        width: 100%;
      }
    }
  }
}
